
/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
.mpr-mat-menu-custom-view {
    &.mat-mdc-menu-panel{
        min-height: 0px;
    }
    .mat-mdc-menu-content {
        height: auto ; 
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .mat-mdc-menu-item {
        line-height:35px;
        height:35px;
        display: flex;
        align-items: center;
        padding:7px 15px;
        min-height: 35px;
    }
    .mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text{
        letter-spacing: normal;
        font-size: 14px;
    }
}   
