@use '../../app/theme/style/variables';

.mpr-tables {
  width: 100%;
  max-height: 500px;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  &.mdc-data-table__table{
    border: 1px solid variables.$mpr-note-text-color;
  }

  .mat-mdc-header-row {
    background-color: map-get(variables.$md-mpr-primary, 900);

    .mat-mdc-header-cell {
      color: white;
      padding-left: 24px;
      font-size: 12px;
      letter-spacing: normal;
    }
  }

  .mat-mdc-row, .mat-mdc-footer-row {
    border-bottom-color: variables.$mpr-table-border-color;
    align-items: stretch;
  }

  .mat-mdc-row:nth-child(odd) {
    background-color: variables.$mpr-table-alt-row-color;
  }

  .mat-mdc-cell, .mat-mdc-footer-cell {
    padding-left: 24px;
    border-right: 1px solid;
    border-right-color: variables.$mpr-table-border-color;
    word-break: break-word;
    letter-spacing: normal;
  }

  .mat-mdc-row:last-child {
    border-bottom: none;
  }

  .mat-mdc-cell:last-child {
    border-right: none;
  }
  .mat-mdc-footer-row {
    background-color: #f5f5f5;
  }
}

.mpr-noresult-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 100px 80px;
  flex-direction: column;

  .mpr-noresutl-error {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    span:first-child {
      margin-right: 10px;
    }
  }

  .mpr-noresult-btn {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mpr-workspace-loader-container{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  flex-direction: column;
}

.mpr-paginator {
  .mat-mdc-paginator-navigation-previous,
  .mat-mdc-paginator-navigation-next {
    border-color: variables.$mpr-border-color-selects;
    border: 1px solid;
    border-radius: 0;
    color: variables.$mpr-mat-arrow-color;
  }

  .current-page {
    border-color: variables.$mpr-border-color-selects;
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 11.5px 30px;
  }
}
