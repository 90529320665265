@use '@angular/material' as mat;
@use '../../app/theme/style/variables';

.mpr-mat-select-field.mat-form-field-appearance-outline {

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .mat-mdc-select-arrow-wrapper {
    background: url(/assets/images/drop-down.svg) no-repeat right #ddd;
    /*To change default icon with provided image*/
    background-position-x: 98%;
    /*Adjust according to width of dropdown*/
    background-color: transparent;
    background-position-y: 35%;

    .mat-mdc-select-arrow {
      opacity: 0;
      margin: 0 7px;
    }
  }

  &.ng-invalid.ng-touched {

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
      border-color: #f44336;
    }
  }

}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0px;
  border-color: #414b66;

}

div.mat-mdc-select-panel.mpr-select-option-panel {
  padding: 0;
}

.mpr-select-option-panel {

  // &.mat-primary .mat-pseudo-checkbox-checked,
  // &.mat-primary .mat-pseudo-checkbox-indeterminate {
  //   background: #046b5c;
  // }

  .mpr-placeholder-option {
    color: variables.$mpr-font-color1;
  }
}

.mat-mdc-select-panel {
  border-radius: 0px !important;
}

.mpr-select-source-spinner {
  margin-left: 10px;

  .mat-mdc-progress-spinner {
    display: inline;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mpr-mat-select-field .mat-select-multiple .mat-select-value {
  max-height: 32px;
  overflow-y: auto;
}

// .mpr-small-field-mat-select {
//   @include mat.form-field-density(-4);
//   letter-spacing: 0px;

//   @supports (top: max(0%)) {
//     .mdc-text-field--outlined {
//       padding-right: 8px;
//       padding-left: 8px;
//     }
//   }
// }

// .mpr-small-field-input {
//   @include mat.form-field-density(-2);
//   letter-spacing: 0px;

//   @supports (top: max(0%)) {
//     .mdc-text-field--outlined {
//       padding-right: 8px;
//       padding-left: 8px;
//     }
//   }
// }