// Layout
$gutter: 40px !default;

// Sidenav
$sidenav-width: 360px !default;
$sidenav-collapsed-width: 68px !default;
$sidenav-width-mobile: 280px !default;

// Toolbar
$toolbar-height-desktop: 68px !default;
$toolbar-height-mobile: 56px !default;

// Topmenu
$topmenu-sticky-position-desktop: $toolbar-height-desktop !default;
$topmenu-sticky-position-mobile: $toolbar-height-mobile !default;

// Typography
$font-family-sans-serif: 'Montserrat', Arial, 'Lucida Grande', sans-serif !default;
$font-family-monospace: 'Montserrat Mono', monospace !default;
$font-family-base: $font-family-sans-serif !default;

// Breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoints: (
  xsmall: 0,
  small: 600px,
  medium: 960px,
  large: 1280px,
  xlarge: 1920px,
) !default;

$md-mpr-primary: (
  50: #e5eaef,
  100: #bdcad8,
  200: #94a7bd,
  300: #6b85a2,
  400: #4a6c91,
  500: #255481,
  600: #1d4c79,
  700: #14436e,
  800: #0e3961,
  900: #0b2949,
  A100: #73a0d1,
  A200: #0075c4,
  A400: #0067be,
  A700: #0060bd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-mpr-accent: (
  50: #fefbe5,
  100: #fcf5c0,
  200: #faee96,
  300: #f7e86c,
  400: #f5e34b,
  500: #f3de27,
  600: #f3cd24,
  700: #f1b51c,
  800: #ef9d14,
  900: #eb7404,
  A100: #f7f683,
  A200: #f4f200,
  A400: #f3dd00,
  A700: #f4ca00,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-mpr-error: (
  50: #ffeaed,
  100: #ffccd0,
  200: #ee9896,
  300: #e3706d,
  400: #ec5049,
  500: #f0402d,
  600: #e2352d,
  700: #d02b27,
  800: #c32420,
  900: #b41613,
  A100: #fd877a,
  A200: #fc4e49,
  A400: #fc053b,
  A700: #d10000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// Colors to be used throughout the application at one central place. [TODO] move other colors here
$mpr-header-green-color: #046b5c;
$mpr-background-color1: #f5f5f5;
$mpr-background-color2: #f5f4f4;
$mpr-success-background-color: #92e3b7;
$mpr-success-font-color: #1a652a;
$mpr-border-color-selects: #414b66;
$mpr-font-color1: #6c6a6a;
$mpr-mat-arrow-color: #046b5c;
$mpr-branding-text-color: #065647;
$mpr-accent-color-1: #f1b51c3b;
$mpr-primary-variant-color-1: #08046b;
$mpr-stepper-linecolor: #046b5c;
$mpr-link-color: #046b5b;
$mpr-stepper-step-icon-defalt-color: #acacba;
$mpr-note-text-color: #707070;
$mpr-divider-color: #b7b7b7;
$mpr-table-border-color: #b1b1b1;
$mpr-table-alt-row-color: #f2f6face;
$mpr-accent-color-2: #f8d884;
$mpr-error-background-color: #f1dede;
$mpr-error-font-color: #C80E09;
$mpr-placeholder-color: #acacba;
$mpr-subheader-color: #f5e6c2;
$mpr-help-text-background-color: #f0efefa5;
$mpr-credentials-text-background-color: #f7d98c;
$mpr-light-blue-text-color: #1c62ad;
$mpr-copy-text-color: #277e71;
$mpr-action-color2: #137465;
$mpr-faint-text-color1: #717171;
$mpr-background-color3: #f7d98c4d;
$mpr-border-color: #7070707c;
$mpr-text-color: #000000;
$mpr-bg-color-1: #e7effa;
$mpr-bg-color-2: #e6f2ea;
$mpr-bg-color-3: #fef7e8;
$mpr-bg-color-3: #fef7e8;
$mpr-bg-color-4: #eeeeee;
$mpr-bg-color-5: #f8f8f8;
$mpr-border-color-1: #e0e0e0;
$mpr-text-color-1: #585757;

// The material default animation curves
$swift-ease-out-duration: 400ms !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;

// Material colors
$mat-colors: (
  white: white,
  black: black,
  light: white,
  dark: rgba(black, 0.87),
  mpr-md-primary: $md-mpr-primary,
  mpr-md-accent: $md-mpr-accent,
  mpr-md-error: $md-mpr-error,
) !default;

$mpr-tiles: (
  border: #c6c9d0,
  footer-color: #9b9b9b,
  blue-tag-color: #1563cc,
  blue-tag-background: #457dfb3b,
  green-tag-color: #0b842b,
  green-tag-color2: #0b8135,
  green-tag-background: #9dffad3b,
  blue-text-color: #124275,
  yellow-tag-background: #fff0ce,
  yellow-tag-color: #7A611D,
  lblue-tag-background:#cceeff,
  lblue-tag-color:#006066
);
