/** Override Material styles */

@use '../../app/theme/style/variables';

.mat-mdc-card {
  margin-bottom: variables.$gutter;
}

.mat-accordion {
  display: block;
  margin-bottom: variables.$gutter;
}

.mat-mdc-checkbox,
.mat-mdc-radio-button,
.mat-mdc-slide-toggle {
  margin-right: variables.$gutter * 0.5;

  [dir='rtl'] & {
    margin-right: auto;
    margin-left: variables.$gutter * 0.5;
  }
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark{
  color: #fff
}

.form-field-full {
  .mat-mdc-form-field {
    width: 100%;
  }
}

.mat-step-header .mat-step-icon {
  height: 45px;
  width: 45px;
  background-color: variables.$mpr-stepper-step-icon-defalt-color;
  color: black;
}

.mat-step-header .mat-step-icon-selected {
  background-color: #fff;
  color: black;
  border: 1px solid #707070;
}
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: map-get(variables.$md-mpr-accent, 700);
  color: black;
}

mat-stepper {
  &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header
    .mat-step-label {
    padding: 13px 0 0 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #0b2949;
    &.mat-step-label-selected {
      font-weight: 600;
    }
  }

  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
    top: 48px;
    border-color: variables.$mpr-stepper-linecolor;
  }

  &.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 48px;
    border-color: variables.$mpr-stepper-linecolor;
  }

  &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::before,
  [dir='rtl']
    &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::before,
  &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::after,
  [dir='rtl']
    &.mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::after {
    width: calc(50% - 21px);
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
  .mat-horizontal-stepper-header-container {
    padding: 0px 16rem;
  }

  .mat-step-icon-state-edit + .mat-step-label-active {
    .mat-step-text-label {
      color: variables.$mpr-stepper-linecolor;
      text-decoration: underline;
    }
  }
}

[class*='mtx-popover-above'] .mtx-popover-direction-arrow {
  width: 1em;
  color: white;
}

.mtx-popover-direction-arrow:after {
  color: map-get(variables.$md-mpr-primary, 900);
  color: #08046b !important;
}

[class*='mtx-popover-below'] .mtx-popover-direction-arrow:after,
[class*='mtx-popover-above'] .mtx-popover-direction-arrow:after {
  color: white;
}

[class*='mtx-popover-below'] .mtx-popover-direction-arrow:after,
[class*='mtx-popover-above'] .mtx-popover-direction-arrow:after {
  border-right-color: transparent;
  border-left-color: transparent;
}

.mtx-popover-direction-arrow:before,
.mtx-popover-direction-arrow:after {
  border-right-color: transparent;
  border-left-color: transparent;
}

.mtx-popover-panel {
  box-shadow: none;
  border: 1px solid map-get(variables.$md-mpr-primary, 900);
}

.mtx-popover-panel {
  .mpr-tooltip-title-bold {
    font-weight: bold;
  }
  .mtx-popover-content {
    font-size: 12px;
    color: map-get(variables.$md-mpr-primary, 900);
  }
}

.mpr-hide-form-field-label-onfocus {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }
}

.mpr-form {
  .mpr-form-block {
    .mpr-form-block-field {
      /*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
      /*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
      /*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
      /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
      .mat-form-field-type-mat-input.mat-form-field-appearance-legacy,
      .mat-form-field-type-mat-chip-list.mat-form-field-appearance-fill {
        &.mat-form-field-disabled {
          /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          .mat-form-field-flex {
            background-color: red;
          }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-wrapper {
          /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          .mat-form-field-flex {
            border: 1px solid variables.$mpr-border-color-selects;
            font-size: 16px;
            padding: 5px;
            background-color: white;
            border-radius: 0;
          }
          /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          .mat-form-field-infix {
            border-top: 0px;
          }
          /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          .mat-form-field-underline {
            height: 0;
            display: none;
          }
        }

        &.mat-form-field-invalid {
          /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
          .mat-form-field-wrapper {
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-flex {
              border-color: variables.$mpr-error-font-color;
            }
          }
        }

        &.mpr-metadata-dataTags-chip-list.mat-form-field-hide-placeholder
          .mat-mdc-input-element::placeholder {
          color: variables.$mpr-placeholder-color;
        }
      }
      .mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label{
        display: inline-flex;
      }
      .mdc-evolution-chip-set .mdc-evolution-chip{
        height: 32px;
      }
    }
  }
}
// Project Form Chip css
.mpr-metadata-dataTags-chip-list {
  .mdc-evolution-chip-set .mdc-evolution-chip{
    height: 32px;
  }
  .mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label{
    display: inline-flex;
  }
}

.mpr-tabs-container {
  .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      width: 500px;
      border-bottom: none;
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-labels {
          .mdc-tab {
            border-bottom: 0px solid;
            font-weight: bold;
            color: #0b2949;
            letter-spacing: normal;
          }
        }
      }
    }
  }
}
.mdc-tab:hover {
  color: #0b2949 !important;
}

.mpr-search-box {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-flex {
    border-color: variables.$mpr-error-font-color;
    border: 1px solid;
    padding: 5px 5px 5px 15px;
    min-width: 250px;
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      border-top: 0;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    height: 0;
    display: none;
  }
}

.mat-mdc-slide-toggle {
  // css for slide track
  .mdc-switch:enabled .mdc-switch__track::after,
  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
  .mdc-switch:enabled:active .mdc-switch__track::after{
    background-color: variables.$mpr-stepper-linecolor;
  }
  .mdc-switch:enabled .mdc-switch__track::before,
  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before,
  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before,
  .mdc-switch:enabled:active .mdc-switch__track::before{
    background-color: #00000061;
  }
  // css for toggle button
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after
  {
    background-color: map-get(variables.$mat-colors, white);
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after
  {
    background-color: map-get(variables.$mat-colors, white);
  }
  
}

/*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
/*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
/*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
.mpr-mat-input-field {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-flex {
    border-color: variables.$mpr-error-font-color;
    border: 1px solid;
    padding: 5px 5px 5px 15px;
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      border-top: 0;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    height: 0;
    display: none;
  }
}
.mat-mdc-tab .mdc-tab-indicator__content--underline{
  margin: 0px 5px;
  padding: 2px;
  background: #f1b51c !important;
  border: none;
}
.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  margin: 0px 5px;
  padding: 2px;
  background: #f1b51c !important;
}

.workspace-wrapper-container {
  .mdc-tab .mdc-tab__content {
    font: normal normal bold 14px/27px Montserrat;
  }
  .mdc-tab {
    opacity: 0.65;
  }
  .mdc-tab--active {
    color: #0b2949;
    opacity: 1;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mpr-mat-input-field .mat-form-field-flex {
  align-items: center;
}

.mpr-s3-file-selection-checkbox {
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-inner-container {
    margin-right: 0;
  }
}

#mpr-mat-sidebar {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-form-field{
  letter-spacing: normal;
}


input.mat-mdc-input-element{
  height: 48px;
}