@use '@angular/material' as mat;
@use '../app/theme/style/reboot-theme';
@use '../app/theme/header/header-theme';
@use '../app/theme/sidebar/sidebar-theme';
@use '@ng-matero/extensions' as mtx;

@include mat.core();

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

// Styles for the app that are based on the current theme.
@mixin theme($theme) {
  /* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
  @include mat.all-legacy-component-themes($theme);
  @include mat.all-component-themes($theme);

  @include reboot-theme.theme($theme);
  @include header-theme.theme($theme);
  @include sidebar-theme.theme($theme);

  @include mtx.all-component-themes($theme);
  // @include mtx.all-experimental-component-themes($theme);

  @include mat.strong-focus-indicators-theme($theme); // A11y 508 Compliance
}