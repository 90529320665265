@use '../../app/theme/style/variables';

.mpr-data-source-label {
  font-size: 15px;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

.mpr-dropdown-row {
  flex: 0 1 75px;
}

.mpr-list-files {
  flex: 1 1 auto;
  min-height: 200px;
  max-height: 500px;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid variables.$mpr-note-text-color;

  .mpr-no-files-text {
    text-align: center;
    font-size: 14px;
    height: 200px;
    line-height: 200px;
    color: variables.$mpr-note-text-color;
  }
}

.file-list-table {
  width: 100%;

  .mat-mdc-header-row {
    background-color: map-get(variables.$md-mpr-primary, 900);

    .mat-mdc-header-cell {
      color: white;
      padding-left: 24px;
    }

    .mat-mdc-header-cell:nth-child(1) {
      border-right: 1px solid variables.$mpr-table-border-color;
    }
  }

  .mat-mdc-row {
    border-bottom-color: variables.$mpr-table-border-color;
  }

  .mat-mdc-row:nth-child(odd) {
    background-color: variables.$mpr-table-alt-row-color;
  }

  .mat-mdc-cell {
    padding-left: 24px;
  }

  .mat-column-serialNum {
    flex: 0 1 100px;
    border-right: 1px solid variables.$mpr-table-border-color;
  }

  .mat-column-fileSize {
    flex: 0 1 200px;
  }

  .mat-column-removeFile {
    flex: 0 1 100px;
  }

  .mpr-remove-file {
    color: map-get(variables.$md-mpr-error, 700);
    cursor: pointer;
  }
}

.file-input-native {
  display: none;
}

.select-file-stats {
  color: black;
  font-weight: 600;
  font-size: 12px;
  padding-bottom: 15px;
}

.select-file-dropdrop-info {
  font-size: 12px;
  font-weight: 600;
  color: variables.$mpr-primary-variant-color-1;
  text-transform: none;
}
